@font-face {
  font-family: 'TheSansLF';
  src: url('../../assets/fonts/TheSans/woff2/TheSansLF_Light.woff2')
      format('woff2'),
    url('../../assets/fonts/TheSans/woff/TheSansLF_Light.woff') format('woff');
  font-weight: 200;
}
@font-face {
  font-family: 'TheSansLF';
  src: url('../../assets/fonts/TheSans/woff2/TheSansLF.woff2') format('woff2'),
    url('../../assets/fonts/TheSans/woff/TheSansLF.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'TheSansLF';
  src: url('../../assets/fonts/TheSans/woff2/TheSansLF_Bold.woff2')
      format('woff2'),
    url('../../assets/fonts/TheSans/woff/TheSansLF_Bold.woff') format('woff');
  font-weight: 700;
}
@font-face {
  font-family: 'TheSansLF';
  src: url('../../assets/fonts/TheSans/woff2/TheSansSC_Bold.woff2')
      format('woff2'),
    url('../../assets/fonts/TheSans/woff/TheSansSC_Bold.woff') format('woff');
  font-weight: 900;
}
@font-face {
  font-family: 'RobotoSlab';
  src: url('../../assets/fonts/RobotoSlab/RobotoSlab-Regular.woff')
      format('woff'), url('../../assets/fonts/RobotoSlab/RobotoSlab-Regular.woff2') format('woff2');
  font-weight: 400;
}
@font-face {
  font-family: 'RobotoSlab';
  src: url('../../assets/fonts/RobotoSlab/RobotoSlab-Bold.woff')
      format('woff'), url('../../assets/fonts/RobotoSlab/RobotoSlab-Bold.woff2') format('woff2');
  font-weight: 700;
}
